    <div class="col-lg-12 ">
    </div>
    <form class="col-lg-12" [formGroup]="requestAccessForm" (ngSubmit)="onSubmit()">
        <div class="form-row">
            <div class="form-group col-lg-3">
                <label class="request-label required">{{"requestAccess.organizationName" |cxTranslate}}</label>
                <input type="text" class="form-control request-data " formControlName="orgName" />
            </div>
            <div class="form-group col-lg-3">
                <label class="request-label required">{{"requestAccess.country" |cxTranslate}}</label>
                <p-dropdown  (onfocus)="getFocus()" [options]="countries" optionValue="isocode"
                    optionLabel="name" placeholder="{{'requestAccess.chooseACountry'|cxTranslate}}"
                    formControlName="country"
                    [styleClass]="requestAccessForm.get('country').invalid && checkFlag?'border-red-500':'border-round-md'">
                </p-dropdown>
            </div>
        </div>
     
    <div class="form-row ">
        <div class="form-group col-lg-6">
            <label class="request-label">{{"requestAccess.companyIdentity" |cxTranslate}}</label>
            <p class="request-data">
                {{"requestAccess.ifAvailableFormValidCompany identification can be uploaded here to help along the
                process" |cxTranslate}}
            </p>
            <div class="DragDropUpload" style="line-height: 3.5">
                <div class="box drag-drop-enabled">
                    <div class="box__input" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
                        <input type="file" id="file" multiple="true" class="box__file" formControlName="document"
                            (change)="uploadImage($event)" #fileUploader />
                        <label for="file">
                            <i data-v-172755ba="" class="sg-icon sg-icon-download cursor-pointer chooseAFile-icon"></i>
                            <span class="select-file-label" style="display: block">{{"requestAccess.chooseAFile"
                                |cxTranslate}}</span>
                        </label>
                        <div class="file-listing" *ngFor="let res of array; let i = index">
                            <div class="uploaded-file">
                                <span class="file-name request-data">{{ res.name }}</span>
                                <span class="remove-file" (click)="remove(i)">
                                    <i class="fa fa-times"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p style="color: red;" class="mt-2" *ngIf="typeFlag">{{"requestAccess.invalidFileType"
                |cxTranslate}}:{{fileType}}</p>
        </div>
    </div>
    <p class="request-data">{{"requestAccess.supportedFiletype" |cxTranslate}}</p>
    <div class="form-row">
        <div class="form-group col-lg-3">
            <label class="request-label">{{"requestAccess.title" |cxTranslate}}</label>
            <input type="text" class="form-control request-data" formControlName="title" />
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-lg-3">
            <label class="request-label required">{{"requestAccess.firstName" |cxTranslate}}</label>
            <input type="text" class="form-control request-data" formControlName="firstName" />
        </div>
        <div class="form-group col-lg-3">
            <label class="request-label required">{{"requestAccess.lastName" |cxTranslate}}</label>
            <input type="text" class="form-control request-data" formControlName="lastName" />
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-lg-3">
            <label class="request-label required">{{"requestAccess.email" |cxTranslate}}</label>
            <input type="text" class="form-control email request-data" formControlName="email" />
        </div>
        <div class="form-group col-lg-3">
            <label class="request-label required">{{"requestAccess.phone" |cxTranslate}}</label>
            <input type="text" class="form-control request-data" formControlName="phone" />
        </div>
    </div>
    <div class="form-row  mt-2">
        <div *ngIf="this.validation" class="visualCaptchaContainer text-center  mb-4"
            [ngClass]="statusvalueinvalid ? 'visualCaptchainvalid' : 'visualCaptcha'">
            <span>
                <ngx-captcha [config]="captchaConfig"></ngx-captcha>
            </span>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-lg-3">
            <button type="submit" class="btn cstm-btn-primary mb-2">{{"requestAccess.sendRequest"
                |cxTranslate}}</button>
        </div>
    </div>
</form>


<!-- Modal -->
<div class="modal" id="exampleModal" tabindex="-1" role="dialog" data-backdrop="true" [ngStyle]={display:this.display}>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">{{"requestAccess.confirmation" |cxTranslate}}</h1>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="onCloseHandled()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="overflow:hidden;">
                <p *ngIf="isSuccess==1">{{"requestAccess.requestSent" |cxTranslate}}</p>
                <p *ngIf="isSuccess==2">{{"requestAccess.SomethingWent" |cxTranslate}}</p>
            </div>
            <div class="modal-footer justify-content-start">
                <button type="button" class="btn cstm-btn-primary" data-dismiss="modal" (click)="onCloseHandled()">
                    {{"requestAccess.ok" |cxTranslate}}
                </button>
            </div>
        </div>
    </div>
</div>