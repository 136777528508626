import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { AppConstants } from '../../../SGRE-shared/constants/app-constant';
import { map } from 'rxjs/operators';
import { BehaviorSubject} from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestAccessService {

  setValue(arg0: { country: string; }) {
    throw new Error('Method not implemented.');
  }
  statuscode: string;
  submitStatus = new BehaviorSubject<number>(0);

  data$ = this.submitStatus.asObservable();

  constructor(private http: HttpClient) { }

  public getCountries() {
    const requestOptions = {
      url:
        environment.siteUrls.getBaseUrl +
        environment.siteUrls.getPrefix +
        environment.siteUrls.getSiteName +
        AppConstants.apiUrls.getCountries
    };
    return this.http.get(requestOptions.url).pipe(map(res => res));
  }

  submitRequestAccess(requestObject?) {
    const formData = new FormData();
    formData.append('basesiteId', requestObject.basesiteID);
    formData.append('country', requestObject?.country);
    formData.append('email', requestObject.email);
    formData.append('firstName', requestObject.firstName);
    formData.append('lastName', requestObject.lastName);
    formData.append('organizationName', requestObject?.organizationName);
    formData.append('phone', requestObject.phone);
    formData.append('title', requestObject.title);
    for (let i = 0; i < requestObject.document.length; i++) {
      formData.append('document', requestObject?.document[i]);
    }
    const requestOptions = {
      url:
        environment.siteUrls.getBaseUrl +
        environment.siteUrls.getPrefix +
        AppConstants.apiUrls.submitRequestAccess
    };
    return this.http.post(requestOptions.url, formData, { observe: 'response' }).pipe(map(res => res));
  }

}
