import { Component, ChangeDetectorRef, HostListener, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxCaptchaService } from '@binssoft/ngx-captcha';
import { RequestAccessService } from '../../services/request-access.service';
import { Router } from '@angular/router';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Subject, takeUntil } from 'rxjs';
@Component({
  selector: 'app-request-access',
  templateUrl: './request-access.component.html',
  styleUrl: './request-access.component.scss'
})
export class RequestAccessComponent implements OnInit, OnDestroy {

  @HostListener('click') onClick() {
    if (this.isSuccess == 1) {
      this.isSuccess == 0;
      this.router.navigate([AppConstants.routeUrls.home]);
      this.display = 'none';
    }
    else {
      this.display = 'none';
    }
  }

  @ViewChild('fileUploader') fileUploader: ElementRef;

  captchIndex: number = 0;
  requestAccessForm!: FormGroup;
  checkFlag: boolean = false;
  users: any;
  data: {} = {};
  modalpopup: boolean;
  captchaStatus: any = null;
  statusvalue: boolean = false;
  statusvalueinvalid: boolean = false;
  statusvaluevalid: boolean;
  validation: boolean = false;
  array: any = [];
  file: any;
  countries: any;
  countriesValue: any;
  maxLimit: boolean = false;
  fileType: string = '';
  display: any;
  isSuccess: any = 0;
  captchaConfig: any = {
    type: 4, // 1 or 2 or 3 or 4
    length: 6,
    cssClass: 'custom',
    back: {
      stroke: "#2F9688",
      solid: "#f2efd2",
    },
    font: {
      color: "#000000",
      size: "35px"
    },
  };
  typeFlag: boolean = false;
  country: any = [];
  statusSub: any;
  textValue: string = '';
  dropdownClass: string = '';
  public phoneNoRegex: RegExp =  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
  unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private changeRef: ChangeDetectorRef,
    private captchaService: NgxCaptchaService,
    private requestAccessService: RequestAccessService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getCountries();
    this.requestAccessForm = new FormGroup({
      orgName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(250)]),
      country: new FormControl('', [Validators.required]),
      document: new FormControl(),
      title: new FormControl('', []),
      firstName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(250)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(250)]),
      email: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(250), Validators.email]),
      phone: new FormControl('', [Validators.required, Validators.pattern(this.phoneNoRegex)]),
    });
  }

  get f() { return this.requestAccessForm.controls; }

  getCountries() {
    this.users = this.requestAccessService.getCountries()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.countries = res.countries;
      });
  }

  OnchangeFile(event: any) {
    this.array.push(event.target.files[0]);
    this.requestAccessForm.patchValue({
      document: this.file,
    });
    this.requestAccessForm.get('document')?.updateValueAndValidity();
  }

  uploadImage(event: any) {
    if (this.array.length > 0)
      this.array.splice(0, 1);
    let file: any = event.currentTarget.files;
    let parts = file[0].name.split('.');
    let index = (parts.length) - 1;
    this.fileType = file[0].name;
    if (parts[index].toLowerCase() != 'png' && parts[index].toLowerCase() != 'docx' &&
      parts[index].toLowerCase() != 'excl' && parts[index].toLowerCase() != 'pdf' &&
      parts[index].toLowerCase() != 'jpeg' && parts[index].toLowerCase() != 'excel' &&
      parts[index].toLowerCase() != 'jpg' && parts[index].toLowerCase() != 'xlsx') {
      this.typeFlag = true;
      return;
    }
    this.typeFlag = false;
    this.array.push(event.currentTarget.files[0]);
  }

  remove(i: any) {
    this.array.splice(i, 1);
    this.fileUploader.nativeElement.value = null;
  }

  onSubmit() {
    this.statusvalueinvalid = false;
    this.modalpopup == false
    this.checkFlag = true;
    if (this.requestAccessForm.invalid) {
      this.captchIndex = 0;
      for (const control of Object.keys(this.requestAccessForm.controls)) {
        this.requestAccessForm.controls[control].markAsTouched();
      }
      return;
    } else {
      this.captchIndex++;
      this.validation = true;
      this.captchaService.captchStatus
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((status) => {
          this.captchaStatus = status;
          if (status == false) {
            this.statusvalueinvalid = true;
            this.statusvaluevalid = false;
          } else if (status == true) {
            this.captchIndex = 0;
            this.statusvalue = true;
            this.statusvaluevalid = true;
            this.statusvalueinvalid = false;
            this.modalpopup = true;
          }
        });

      this.data = {
        basesiteID: 'sgre',
        country: this.requestAccessForm.value.country,
        email: this.requestAccessForm.value.email,
        firstName: this.requestAccessForm.value.firstName,
        lastName: this.requestAccessForm.value.lastName,
        organizationName: this.requestAccessForm.value.orgName,
        phone: this.requestAccessForm.value.phone,
        title: this.requestAccessForm.value.title,
        document: this.array,
      };
      if (this.statusvalue == true) {
        this.validation = false;
        this.modalpopup == true
        this.globalService.loadingSubject.next(true);
        this.requestAccessService.submitRequestAccess(this.data)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(res => {
            this.statusSub = res.status;
            if (this.statusSub == '201') {
              this.globalService.loadingSubject.next(false);
              this.isSuccess = 1;
              this.showModal()
              this.requestAccessForm.reset();
            } else {
              this.globalService.loadingSubject.next(false);
              this.isSuccess = 2;
              this.showModal()
            }
            this.checkFlag = false;
          });
      }
      else {
        if (this.captchIndex > 1) {
          this.statusvalueinvalid = true;
        }
      }
    }
  }

  getFocus() {
    this.dropdownClass = 'p-dropdown';
  }

  showModal() {
    this.display = 'block';
  }

  onCloseHandled() {
    if (this.isSuccess == true) {
      this.router.navigate([AppConstants.routeUrls.home]);
    }
    else {
      this.display = 'none';
    }
    this.changeRef.detectChanges();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    if (this.array.length > 0)
      this.array.splice(0, 1);
    event.preventDefault();
    let file: any = event.dataTransfer.files;
    let parts = file[0].name.split('.');
    let index = (parts.length) - 1;
    this.fileType = file[0].name;
    if (parts[index].toLowerCase() != 'png' && parts[index].toLowerCase() != 'docx' &&
      parts[index].toLowerCase() != 'excl' && parts[index].toLowerCase() != 'pdf' &&
      parts[index].toLowerCase() != 'jpeg' && parts[index].toLowerCase() != 'excel' &&
      parts[index].toLowerCase() != 'jpg' && parts[index].toLowerCase() != 'xlsx') {
      this.typeFlag = true;
      return;
    }
    this.typeFlag = false;
    this.array.push(event.dataTransfer.files[0]);
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
    this.captchaService.unsubscribe();
  }

}
